/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { colors } from "app/shared/styles/_colors";
// import TextBase from 'app/shared/core/Text';
import { css } from 'app/styled-system/css';

export const inputRequiredMarkCX = css({
    color: 'hpxRed400'
});

export const inputContainerCX = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%'
});

export const getInputCX = (props: {
    border?: boolean,
    borderRadius?: boolean,
    centerText?: boolean,
    disabled?: boolean,
    fixedSize?: boolean,
    hpxStyle?: string,
    inactive?: boolean,
    inputButtonGroupPosition?: string,
    isTextArea?: boolean,
    paddingRight?: string,
    size?: string,
    type?: string,
    width?: string,
}) => css({
    WebkitAppearance: 'none',
    margin: 0,
    width: '100%',
    flexGrow: 1,

    textOverflow: !props.isTextArea ? 'ellipsis' : '',
    // Placeholder fallbacks
    '&::placeholder': {
        color: 'hpxGrey500',
        textOverflow: 'ellipsis'
    },
    '&::-webkit-input-placeholder': {
        color: 'hpxGrey500',
        textOverflow: 'ellipsis'
    },
    '&:-moz-placeholder': {
        color: 'hpxGrey500',
        textOverflow: 'ellipsis'
    },
    '&::-moz-placeholder': {
        color: 'hpxGrey500',
        textOverflow: 'ellipsis'
    },
    '&:-ms-input-placeholder': {
        color: 'hpxGrey500',
        textOverflow: 'ellipsis'
    },

    color: props.disabled || props.inactive ? 'hpxGrey600' : 'hpxBlue600',

    borderBottomRightRadius: props.inputButtonGroupPosition === 'after' || props.inputButtonGroupPosition === 'left' || props.inputButtonGroupPosition === 'both' ? '0px' : '0',
    borderTopRightRadius: props.inputButtonGroupPosition === 'after' || props.inputButtonGroupPosition === 'both' ? '0px' : '',
    borderRight: props.inputButtonGroupPosition === 'after' || props.inputButtonGroupPosition === 'both' ? '1px solid #ffffff' : '',
    borderBottomLeftRadius: props.inputButtonGroupPosition === 'before' || props.inputButtonGroupPosition === 'both' ? '0px' : '',
    borderLeft: props.inputButtonGroupPosition === 'before' || props.inputButtonGroupPosition === 'both' ? '1px solid #ffffff' : '',

    textAlign: props.centerText ? 'center' : 'left',

    backgroundColor: props.disabled ? 'hpxGrey100' : 'transparent',
    borderRadius: props.borderRadius || props.border ? '4px' : '0',
    border: props.hpxStyle === 'error' ? '1px solid #c93a1a !important' : (props.border ? '1px solid #949494' : 'none'),
    _hover: {
        outline: 'none',
        border: props.hpxStyle === 'error' ? '1px solid #c93a1a !important' : (props.border ? '1px solid #007a70' : 'none')
    },

    borderBottom: props.width === 'full' ? '1px solid #f2f2f2' : '',

    fontSize: 'md',
    lineHeight: 'md',
    height: props.type === 'textarea' ? '56px' : (props.size === 'lg' ? '48px' : (props.size === 'xl' ? '100%' : '34px')),
    padding: props.size === 'lg' || props.size === 'xl' ? '13px 16px' : '',
    paddingRight: props.paddingRight === 'lg' ? '90px' : (props.paddingRight === 'md' ? '50px' : '8px'),
    letterSpacing: '0.5px',

    overflow: !props.isTextArea ? 'hidden' : '',
    whiteSpace: !props.isTextArea ? 'nowrap' : ''
});

export const inputRightCX = css({
    display: 'flex',
    position: 'absolute',
    right: '0',
    top: '0',
    height: '100%',
    cursor: 'pointer',
});

export const inputClearCX = css({
    height: '100%',
    width: '45px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
});

export const inputCancelCX = css({
    height: '100%',
    width: '45px',
    paddingTop: '2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
});

export const inputCancelTextCX = css({
    color: 'hpxGrey600',
    fontWeight: 'bold'
});

export const alertTextCX = css({
    color: 'hpxRed400'
});