// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';

const StyledLabel = styled.label`
    ${font.sm};
    color: ${colors['$hpx-grey-600']};
    display: block;
    margin-bottom: 4px;
    user-select: none;
`;

class Label extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, className, disabled } = this.props;
        const rest = omit(this.props, ['children', 'className', 'disabled']);

        return (
            <StyledLabel {...rest} className={cx(className, { disabled })}>
                {children}
            </StyledLabel>
        );
    }
}

export default Label;
