// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { stacked, inline } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';

export const FilterBarSection = styled.section`
    align-items: center;
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 8px 45px 8px 0;
    position: absolute;
    white-space: nowrap;
    width: 100%;

    & > button {
        &:not(:last-of-type) {
            ${inline._1x};
        }
    }
`;

/**
 * General filter styling
 */
export const GeneralFilterSection = styled.section`
    ${stacked._2x};
    position: relative;
`;

export const GeneralFilterHeader = styled.header`
    ${stacked._xs};
`;

export const GeneralFilterLabel = styled.p`
    ${font.sm};
    color: ${colors['$hpx-grey-600']};
    display: inline-block;
`;

export const GeneralFilterResetButton = styled.button`
    cursor: pointer;
    float: right;
    ${font.sm};
    background: none;
    color: ${colors['$hpx-grey-600']};
    text-decoration: underline;
`;

export const GeneralApplyFilterButton = styled.button`
    float: right;
    ${font.sm};
    background: none;
    color: ${colors['$hpx-teal-500']};
    cursor: pointer;
`;
