import { css } from 'app/styled-system/css';

const smoothTransition = {
    transition: 'all 0.15s ease-in-out'
};
const noUserSelect = {
    WebkitTouchCallout: 'none',
    userSelect: 'none', /* Chrome and Opera */
    WebkitTapHighlightColor: 'transparent'
};

const textOverflow = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};

const widthInputMdAndUp = '400px';
const widthInputXlAndUp = '720px';

export const autoCompleteContainerCx = () => {
    return {
        base: css({
            height: '100%',
            textAlign: 'left',
            'md': {
                width: '450px'
            }
        }),
        full: css({
            width: 'auto',
            height: 'auto',
            paddingRight: '16px',
            'md': {
                padding: 0,
                borderRight: `1px solid #f2f2f2`,
                height: '100%'
            }
        }),
        fullActive: css({
            border: 'none',
            position: 'fixed',
            left: 0,
            height: '44px',
            'md': {
                position: 'inherit',
                height: '100%'
            }
        }),
        homepage: css({
            position: 'relative',
            display: 'block',
            width: '100%',
            height: '40px',
            'md': {
                height: '48px',
                width: '245px',
                minWidth: '245px'
            }
        }),
        srpFull: css({
            borderRight: 'none',
            padding: 'none',
            paddingRight: '16px',
            width: '100%',
            md: {
                paddingRight: '0',
                width: '158px'
            },
            xxl: {
                width: '268px'
            }
        })
    };
};

export const autoCompleteInputContainerCx = () => {
    return {
        base: css({
            position: 'relative',
            height: '100%'
        }),

        full: css({
            position: 'relative',
            zIndex: 'zIndexLoader',
            width: '100%',
            height: '32px',
            marginRight: '16px',
            borderRadius: '4px',
            border: '1px solid #949494',
            'md': {
                borderRadius: '0',
                height: '100%',
                margin: '0'
            }
        }),

        fullActive: css({
            position: 'relative',
            zIndex: 'zIndexLoader',
            width: '100%',
            height: '100%',
            minHeight: '44px',
            padding: '0 16px',
            border: '1px solid #949494',
            borderRadius: '0',
            background: 'hpxWhite',
            // margin: '0 16px',
            'md': {
                maxWidth: '808px'
            }
        }),

        homepage: css({
            border: '0',
            marginLeft: '0',
            height: '40px',
            'md': {
                height: '48px'
            }
        }),

        srpFull: css({
            marginRight: '0',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            border: '1px solid #949494',
            borderRadius: '2px'
        }),

        srpFullActive: css({
            position: 'relative',
            width: '100%',
            height: 'auto',
            marginBottom: '8px',
            'md': {
                width: '400px',
                marginLeft: '0'
            },
            'xxl': {
                width: '720px',
                marginLeft: '8px'
            }
        })
    };
};

export const searchIconCx = () => {
    return {
        base: css({
            width: '16px',
            height: '16px',
            position: 'relative',
            pointerEvents: 'none',
            top: '12px',
            left: '15px',
            zIndex: 'zIndexStep',
            'md': {
                top: '14px',
                left: '18px'
            }
        }),

        full: css({
            top: '7px',
            left: '10px',
            'md': {
                top: 'auto',
                left: '18px'
            }
        }),

        fullActive: css({
            top: '13px',
            left: '16px',
            'md': {
                left: '56px'
            }
        }),

        srpFull: css({
            top: 'auto',
            'md': {
                left: '8px'
            }
        }),

        srpFullActive: css({
            left: '0px',
            'md': {
                left: '8px'
            }
        })
    };
};

export const dropdownCx = () => {
    return {
        base: css({
            position: 'relative',
            width: '100%',
            maxWidth: '100vw',
            background: 'hpxWhite',
            zIndex: 'zIndexOverOverlay !important',
            maxHeight: '100vh',
            borderRadius: '4px',
            marginTop: '8px',
            'md': {
                width: '450px',
                maxWidth: '808px',
                marginTop: '16px',
                maxHeight: 'none',
                overflowX: 'hidden',
                overflowY: 'auto'
            }
        }),

        full: css({
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            top: '44px',
            left: '0px',
            maxHeight: '100vh',
            padding: '0 16px 16px 16px',
            borderRadius: '0',
            marginTop: '0',
            'md': {
                height: 'auto',
                maxHeight: '80vh',
                top: '0px'
            }
        }),

        homepage: css({
            position: 'relative',
            padding: '0',
            top: '0'
        }),

        srpFull: css({
            position: 'absolute',
            top: '0px',
            maxHeight: 'calc(100vh - 48px - 56px)',
            paddingTop: '64px',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarGutter: 'stable both-edges',
            'md': {
                marginTop: 0,
                maxWidth: '452px',
                width: '452px'
            },
            'xxl': {
                maxWidth: '880px',
                width: '880px'
            }
        })
    };
};

export const sectionCx = () => {
    return {
        base: css({
            position: 'relative'
        }),

        full: css({
            'md': {
                marginLeft: '0'
            }
        }),

        fullSrp: css({
            'md': {
                width: widthInputMdAndUp,
                marginLeft: '-30px'
            },
            'xxl': {
                width: 'auto',
                marginLeft: '8px'
            }
        }),

        notFirstOfTypeBefore: css({
            _before: {
                content: "''",
                position: 'absolute',
                left: '0',
                top: '0',
                height: '1px',
                width: '100%',
                maxWidth: widthInputXlAndUp,
                borderTop: '1px solid #f2f2f2'
            }
        }),

        headerFull: css({
            'md': {
                paddingTop: '16px'
            }
        })
    };
};

export const inputCx = () => {
    return {
        base: css({
            height: '100%'
        }),

        full: css({
            paddingLeft: '0'
        }),

        fullActive: css({
            paddingLeft: '0px',
            'md': {
                paddingLeft: '0px'
            }
        }),

        homepage: css({
            fontSize: 'sm',
            'md': {
                paddingLeft: '24px',
                fontSize: 'inherit'
            }
        }),

        srpFull: css({
            width: '100%'
        }),

        srpFullActive: css({
            marginLeft: '8px',
            'md': {
                marginLeft: '16px'
            }
        }),

        dimmedText: css({
            color: 'hpxGrey600 !important'
        })
    };
};

export const itemContainerCx = () => {
    return {
        base: css({
            ...smoothTransition,
            ...noUserSelect,
            ...textOverflow,
            display: 'flex',
            alignItems: 'center',
            height: '56px',
            maxWidth: widthInputXlAndUp,
            cursor: 'pointer',
            borderBottom: '1px solid #f2f2f2',
            textAlign: 'left',
            color: 'hpxBlue600',
            padding: '0 16px',
            '&:last-child': {
                borderBottom: '0px'
            },
            _focus: {
                outlineColor: 'hpxGrey600',
                outlineOffset: '1px',
                outlineStyle: 'auto',
                outlineWidth: '1px'
            },
            'md': {
                height: '60px'
            }
        }),

        active: css({
            backgroundColor: 'hpxGrey100'
        }),

        itemFull: css({
            padding: '0',
            'md': {
                padding: '0 8px'
            }
        }),

        itemBorderTop: css({
            borderTop: '1px solid #f2f2f2'
        })
    };
};

export const itemIconContainerCX = css({
    display: 'flex',
    alignItems: 'center'
});

export const itemHeaderCx = () => {
    return {
        base: css({
            color: 'hpxGrey600',
            fontSize: 'sm',
            maxWidth: widthInputXlAndUp,
            paddingTop: '16px',
            paddingLeft: '16px'
        }),

        full: css({
            paddingLeft: '0',
            'md': {
                paddingTop: '32px'
            }
        })
    };
};

export const itemIconCX = css({
    color: 'hpxGrey600',
    width: '16px',
    minWidth: '16px',
    marginRight: '12px'
});

export const itemNoIconCX = css({
    width: '16px',
    minWidth: '16px'
});

export const itemTextCX = css({
    ...textOverflow,
    flexGrow: '1'
});

export const itemNameCX = css({
    ...textOverflow,
    display: 'block',
    maxWidth: 'calc(100vw - 44px)',
    md: {
        maxWidth: widthInputXlAndUp
    }
});

export const itemDescriptionCX = css({
    ...textOverflow,
    display: 'block',
    fontSize: 'tiny',
    color: 'hpxGrey600',
    maxWidth: 'calc(100vw - 44px)',
    md: {
        maxWidth: widthInputXlAndUp
    }
});

export const itemRecentCX = css({
    color: 'hpxGrey600'
});

export const itemRecentNumCX = css({
    color: 'hpxRed500',
    fontSize: 'tiny',
    padding: '0 0 8px 24px',
    md: {
        padding: '0 8px 8px 32px'
    }
});

export const itemMatchCX = css({
    fontWeight: 'bold'
});

export const helpContainerCX = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '48px 0',
    maxWidth: '100vw',
    cursor: 'default',
    textAlign: 'center',
    color: 'hpxGrey600',
    fontSize: 'md',
    borderBottom: '1px solid #f2f2f2'
});

export const helpTermCX = css({
    color: 'hpxBlue600',
    fontWeight: 'bold'
});

export const helpDescriptionCX = css({
    paddingTop: '8px',
    fontSize: 'sm'
});

export const loginContainerCX = css({
    position: 'relative',
    padding: '26px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    _before: {
        content: '',
        position: 'absolute',
        left: '0',
        top: '0',
        height: '1px',
        width: '100%',
        maxWidth: widthInputXlAndUp,
        borderTop: '1px solid #f2f2f2'
    }
});

export const loginTextCX = css({
    fontSize: 'sm',
    color: 'hpxGrey600',
    marginBottom: '16px'
});
