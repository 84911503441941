// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';
import { zindex } from 'app/shared/styles/_zindex';
import { colors } from 'app/shared/styles/_colors';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${zindex['$z-index-overlay']};
    background: ${colors['$hp-modal-overlay']};
`;

class Modal extends Component {
    static propTypes = {
        children: PropTypes.node,
        forceOverlay: PropTypes.bool,
        forceNoOverlay: PropTypes.bool,
        modalNavOffset: PropTypes.bool
    };

    static defaultProps = {
        children: {},
        forceOverlay: false,
        forceNoOverlay: false,
        modalNavOffset: false
    };

    yieldHidePopup = yieldCallback(() => {
        this.props.onHidePopup();
    });

    handleBackdropClick = (e) => {
        if (e) {
            e.stopPropagation();
        }

        if (e.target === e.currentTarget) {
            this.yieldHidePopup();
        }
    };

    render() {
        const { children, isMobile, forceOverlay, forceNoOverlay, modalNavOffset } = this.props;
        const childClassName = children.props ? children.props.className : '';
        let showOverlay = !isMobile || forceOverlay;
        if (forceNoOverlay) {
            showOverlay = false;
        }

        return (
            <>
                {showOverlay && (
                    <ModalOverlay
                        modalNavOffset={modalNavOffset}
                        className={cx({ 'Modal-nav-offset': modalNavOffset })}
                        onClick={this.handleBackdropClick}
                    />
                )}
                {React.cloneElement(children, {
                    className: cx(childClassName, {
                        'Modal-child': true
                    }),
                    style: {
                        boxShadow: '0 1px 30px 0 rgba(0, 0, 0, 0.15)',
                        zIndex: `${zindex['$z-index-loader']}`
                    }
                })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const screenWidth = state.app.device.screenWidth;
    return {
        isMobile: screenWidth === 'sm' || screenWidth === 'xs'
    };
};

export default connect(mapStateToProps)(Modal);
