// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { inline } from 'app/shared/styles/_spacing';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import * as S from './styles';

const { bool, string, oneOf, any } = PropTypes;
const StyledIconSpan = styled.span`
    ${inline._1x};
`;

class Button extends Component {
    static propTypes = {
        btnType: oneOf([
            'primary',
            'secondary',
            'tertiary',
            'text-color',
            'text-color-outline',
            'text-color-light',
            'default',
            'warning',
            'alert-secondary',
            'primary-outline',
            'primary-inactive',
            'dark'
        ]),
        children: any,
        className: string,
        disabled: bool,
        full: bool,
        htmlTag: string,
        rounded: bool,
        hideInputBorder: bool,
        inputButtonGroupPosition: string,
        size: oneOf(['sm', 'md', 'lg']),
        bold: bool
    };

    static defaultProps = {
        btnType: 'primary',
        children: null,
        className: '',
        disabled: false,
        htmlTag: 'button',
        full: false,
        rounded: false,
        hideInputBorder: false,
        inputButtonGroupPosition: null,
        size: 'md',
        bold: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        const rest = omit(this.props, [
            'bold',
            'btnType',
            'children',
            'className',
            'dispatch',
            'full',
            'hideInputBorder',
            'icon',
            'inputButtonGroupPosition',
            'rounded',
            'size',
            'htmlTag'
        ]);
        const {
            bold,
            btnType,
            children,
            className,
            disabled,
            full,
            hideInputBorder,
            htmlTag: CustomTag,
            icon,
            inputButtonGroupPosition,
            rounded,
            size
        } = this.props;

        return (
            <S.Button
                {...rest}
                as={CustomTag}
                size={size}
                full={full}
                inputButtonGroupPosition={inputButtonGroupPosition}
                hideInputBorder={hideInputBorder}
                btnType={btnType}
                bold={bold}
                disabled={disabled}
                rounded={rounded}
                className={className}
            >
                {icon && <StyledIconSpan>{icon}</StyledIconSpan>}
                {children}
            </S.Button>
        );
    }
}

export default Button;
