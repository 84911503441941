import React from 'react';
import * as S from './styles';
import omit from 'lodash/omit';

interface VisuallyHiddenProps extends React.HTMLAttributes<HTMLElement> {
    htmlTag?: keyof JSX.IntrinsicElements;
    children?: React.ReactNode;
}

const VisuallyHidden = (props: VisuallyHiddenProps) => {
    const { htmlTag, children } = props;
    const rest = omit(props, ['htmlTag', 'children']);
    return (
        <S.VisuallyHidden as={htmlTag || 'span'} {...rest}>
            {children}
        </S.VisuallyHidden>
    );
};

export default VisuallyHidden;