import { css } from 'app/styled-system/css';

// Autocomplete box displayed on Homepage
export const autocompleteHCX = css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDir: 'column',
    alignItems: 'center'
});

export const autocompleteHContainerCX = css({
    display: 'flex',
    flexDir: { base: 'column', md: 'row' },
    width: "100%",
    '&.AutocompleteV2-h': {
        width: '256px',
        minWidth: '245px'
    }
});

export const autocompleteHFiltersCX = css({
    display: 'flex',
    flexDirection: { base: 'column', md: 'row' }
});

export const autocompleteHItemsCX = css({
    display: 'flex',
    margin: { base: '8px 0 28px', md: '0' }
});

export const autocompleteHItemCX = css({
    height: { base: '40px', md: '48px' },
    margin: { base: '0', md: '0 16px' },
    flexGrow: '1',
    '&:first-of-type': {
        marginRight: { base: '8px', md: '0' }
    }
});

export const autocompleteHDropdownCX = css({
    width: { base: 'calc(100vw - 32px)', md: '310px' },
    padding: '16px'
});

// Autocomplete box displayed on Homepage w/ filter descriptions
export const autocompleteHRCX = css({
    width: { base: '100%', md: '600px' }
});

export const autocompleteHRInputContainerCX = css({
    display: 'flex',
    justifyContent: 'space-between'
});

export const autocompleteV2CX = css({
    flexGrow: '1',
    marginRight: '16px',
    maxWidth: '450px',
    '&.AutocompleteV2-h': {
        flexGrow: '1',
        marginRight: '16px',
        maxWidth: '450px'
    }
});

export const autocompleteHRContainerMobileCX = css({
    display: 'flex',
    flexDir: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
});

export const autocompleteHRFilterContainerCX = css({
    fontSize: 'sm',
    lineHeight: 'sm',
    display: 'flex',
    width: '100%',
    justifyContent: 'flexStart',
    paddingTop: '16px',
    paddingBottom: '4px'
});

export const autocompleteHRFilterDescriptionCX = css({
    maxWidth: '450px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
});

export const autocompleteHRFilterCX = css({
    whiteSpace: 'nowrap',
    paddingRight: '8px'
});

export const autocompleteHRResetCX = css({
    fontSize: 'sm',
    lineHeight: 'sm',
    paddingBottom: '24px',
    md: {
        padding: '0',
        paddingLeft: '18px'
    }
});

export const getAutocompleteHResetAlertHiddenCX = (showResetFilterAlert = false) => css({
    fontSize: 'sm',
    lineHeight: 'sm',
    paddingTop: '16px',
    color: 'hpxGrey600',
    width: '100%',
    visibility: showResetFilterAlert ? 'visible' : 'hidden',
    transform: showResetFilterAlert ? 'translate(0px, 0px)' : 'translate(0px, 30px)',
    transition: 'all ease 300ms'
});

export const lastFilterUsedSpanCX = css({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'
});