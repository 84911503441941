// App.tsx
import React, { FC } from 'react';
import { SliderTrack } from './styles';

// Define the Props interface including state
interface TrackProps extends React.HTMLAttributes<HTMLDivElement> {
    index: number; // Assuming 'index' is a number; adjust if different
}

const Track: FC<TrackProps> = (props) => {
    return <SliderTrack {...props} index={props.index} />;
};

export default Track;
