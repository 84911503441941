import isNumber from 'lodash/isNumber';

interface SearchItemProps {
    areaType?: string;
    description?: string;
    gaEvent?: object;
    name?: string;
    nameV2?: string;
    numberOfNewResults?: number | null;
    resourceId?: string;
    type?: string;
    url?: string;
}

class SearchItem {
    areaType: string;
    description: string;
    gaEvent: object;
    name: string;
    nameV2: string;
    numberOfNewResults: number | null;
    resourceId: string;
    type: string;
    url: string;

    constructor({
        areaType = '',
        description = '',
        gaEvent = {},
        name = '',
        nameV2 = '',
        numberOfNewResults = null,
        resourceId = '',
        type = '',
        url = ''
    }: SearchItemProps = {}) {
        this.areaType = areaType;
        this.description = description;
        this.gaEvent = gaEvent;
        this.name = name;
        this.nameV2 = nameV2;
        this.resourceId = resourceId;
        this.type = type;
        this.url = url;
        if (isNumber(numberOfNewResults)) {
            this.numberOfNewResults = numberOfNewResults;
        } else {
            this.numberOfNewResults = null;
        }
    }
}

export default SearchItem;