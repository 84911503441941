// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { cx as pandaCX } from 'app/styled-system/css';
import get from 'lodash.get';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import constants from 'app/shared/constants/ValueConstants';
import IconX from 'images/icons/x.svg';
import Label from 'app/shared/core/Label';
import {
    inputContainerCX,
    getInputCX,
    inputRequiredMarkCX,
    inputCancelTextCX,
    inputCancelCX,
    inputClearCX,
    inputRightCX,
    alertTextCX
} from './styles';
import Text from 'app/shared/core/Text';

const { bool, func, object, oneOf, oneOfType, string } = PropTypes;
class Input extends React.Component {
    static propTypes = {
        border: bool,
        borderRadius: bool,
        centerText: bool,
        className: string,
        customTagClassName: string,
        disabled: bool,
        fixedSize: bool,
        focusInput: bool,
        help: string,
        hpxStyle: string,
        inactive: bool,
        includeClear: bool,
        includeCancel: bool,
        inputButtonGroupPosition: string,
        isActive: bool,
        onCancel: func,
        onClear: func,
        label: oneOfType([string, object]),
        required: bool,
        size: oneOf(['sm', 'md', 'lg', 'xl']),
        type: string,
        width: string
    };

    static defaultProps = {
        border: true,
        borderRadius: false,
        centerText: false,
        className: '',
        customTagClassName: null,
        disabled: false,
        fixedSize: false,
        focusInput: false,
        help: '',
        hpxStyle: '',
        inactive: false,
        includeClear: false,
        includeCancel: false,
        inputButtonGroupPosition: null,
        isActive: false,
        onCancel: () => { },
        onClear: () => { },
        label: '',
        required: false,
        size: 'md',
        type: 'text',
        width: ''
    };

    constructor(props) {
        super(props);
        this.input = React.createRef();
    }

    componentDidMount() {
        const { focusInput } = this.props;

        if (focusInput) {
            this.focusInput();
        }
    }

    handleClear = () => {
        const { onClear } = this.props;
        onClear();
        this.focusInput();
    };

    handleClearKeyDown = (event) => {
        const { which } = event;
        if (which === constants.ENTER_KEYCODE || which === constants.SPACE_KEYCODE) {
            event.stopPropagation();
            this.handleClear();
        }
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    handleCancelKeyDown = (event) => {
        const { which } = event;
        if (which === constants.ENTER_KEYCODE || which === constants.SPACE_KEYCODE) {
            event.stopPropagation();
            this.handleCancel();
        }
    };

    focusInput = () => {
        this.input.current.focus();
    };

    handleKeyDown = (e) => {
        const { onKeyDown } = this.props;
        const keyCode = e.which;
        /*
         * necessary for any component using Input that has also has a listener on ESC key because without
         * calling onKeyDown explicitly, ESC would blur the input and the event would not bubble up
         */
        if (keyCode === constants.ESC_KEYCODE) {
            e.target.blur();
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    render() {
        const {
            border,
            borderRadius,
            centerText,
            className,
            customTagClassName,
            disabled,
            fixedSize,
            help,
            hpxStyle,
            id,
            inactive,
            includeCancel,
            includeClear,
            inputButtonGroupPosition,
            isActive,
            label,
            required,
            size,
            type,
            value,
            width
        } = this.props;
        const rest = omit(this.props, [
            'border',
            'borderRadius',
            'centerText',
            'customTagClassName',
            'dispatch',
            'fixedSize',
            'focusInput',
            'help',
            'hpxStyle',
            'inactive',
            'includeCancel',
            'includeClear',
            'inputButtonGroupPosition',
            'isActive',
            'label',
            'onCancel',
            'onClear',
            'size',
            'width',
            'aria-describedby',
            // type=textarea is not valid for <textarea> elements
            type === 'textarea' && 'type'
        ]);
        const CustomTag = type === 'textarea' ? 'textarea' : 'input';
        let paddingRight = null;
        if (isActive) {
            if (includeClear && includeCancel && value) {
                paddingRight = 'lg';
            } else if (includeClear || includeCancel) {
                paddingRight = 'md';
            }
        }

        const errorId = id ? id + '-error' : '';

        const ariaDescribedBy = [
            errorId,
            get(this.props, 'aria-describedby', '')
        ].filter((str) => str !== '');

        const isTextArea = type === 'textarea';
        let resizeStyle = fixedSize || isTextArea ? { resize: fixedSize ? 'none' : 'vertical' } : {};
        return (
            <div className={cx('Input', className)}>
                {label && (
                    <Label {...id && { htmlFor: id }}>
                        {required && <span className={inputRequiredMarkCX} data-name="inputRequiredMarkCX" aria-hidden="true">{'* '}</span>}
                        {label}
                        {!required && ' (optional)'}
                    </Label>
                )}
                {includeClear || includeCancel ? (
                    <div className={inputContainerCX}>
                        <input
                            {...rest}
                            {...ariaDescribedBy.length > 0 && { 'aria-describedby': ariaDescribedBy.join(' ') }}
                            {...help && { 'aria-invalid': true }}
                            className={pandaCX('Input-element', customTagClassName, getInputCX({
                                inputButtonGroupPosition,
                                centerText,
                                disabled,
                                borderRadius,
                                border,
                                hpxStyle,
                                fixedSize,
                                width,
                                inactive,
                                size,
                                paddingRight,
                                type,
                                isTextArea
                            }))}
                            data-name="inputCX"
                            as={CustomTag}
                            ref={this.input}
                            onKeyDown={this.handleKeyDown}
                            aria-required={required}
                            style={resizeStyle}
                        />
                        {isActive && (
                            <div className={inputRightCX}>
                                {includeClear && value && (
                                    <button
                                        className={inputClearCX}
                                        data-name="inputClearCX"
                                        aria-label="Clear input"
                                        onClick={this.handleClear}
                                        onKeyDown={this.handleClearKeyDown}
                                    >
                                        <img src={IconX} alt="Clear input value" height="12" width="12" />
                                    </button>
                                )}
                                {includeCancel && (
                                    <button
                                        className={inputCancelCX}
                                        data-name="inputCancelCX"
                                        onClick={this.handleCancel}
                                        onKeyDown={this.handleCancelKeyDown}
                                    >
                                        <Text className={inputCancelTextCX} size="tiny">
                                            Cancel
                                        </Text>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <input
                        {...rest}
                        {...ariaDescribedBy.length > 0 && { 'aria-describedby': ariaDescribedBy.join(' ') }}
                        {...help && { 'aria-invalid': true }}
                        className={pandaCX('Input-element', customTagClassName, getInputCX({
                            inputButtonGroupPosition,
                            centerText,
                            disabled,
                            borderRadius,
                            border,
                            hpxStyle,
                            fixedSize,
                            width,
                            inactive,
                            size,
                            paddingRight,
                            type,
                            isTextArea
                        }))}
                        data-name="inputCX"
                        as={CustomTag}
                        ref={this.input}
                        onKeyDown={this.handleKeyDown}
                    />
                )}
                {help && (
                    <Text
                        className={alertTextCX}
                        data-name="alertTextCX"
                        size="sm"
                        aria-live="assertive"
                        role="alert"
                        {...errorId && { id: errorId }}
                    >
                        {help}
                    </Text>
                )}
            </div>
        );
    }
}
export default connect(
    null,
    null,
    null,
    { forwardRef: true }
)(Input);
