import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';

export const BaseOverlay = styled.aside`
    position: fixed;
    top: 90px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${zindex['$z-index-overlay']};
    background: hsla(0, 0%, 100%, 0.9);
`;

export const BaseDialog = styled.article`
    position: absolute;
    min-height: 100px;
    background: ${colors['$hpx-white']};
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid ${colors['$hpx-grey-500']};
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    z-index: ${zindex['$z-index-loader']};
`;

export const BaseFooter = styled.footer`
    text-align: center;
    padding: 12px;
    border-top: 1px solid ${colors['$hpx-grey-100']};
    margin-top: 12px;
`;

export const BaseButton = styled.button`
    cursor: pointer;
    background: none;
    width: 100%;
    color: ${colors['$hpx-teal-500']};
`;
