// @ts-nocheck
/* eslint-enable */
const ReactDOM = require('react-dom');
import cx from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import DEPRECATED_FilterActions from 'app/shared/flux/actions/DEPRECATED_FilterActions';
import IconArrowGrey from 'images/icons/arrow-grey.svg';
import Modal from 'app/shared/modules/Modal';
import * as S from 'app/shared/modules/filter/ButtonDropdown/styles';
import constants from 'app/shared/constants/ConstantsBundle';

const { bool, func, node, string } = PropTypes;

class ButtonDropdown extends React.Component {
    static propTypes = {
        canClose: bool,
        children: node,
        className: string,
        dropdownRight: bool,
        forceOverlay: bool,
        forceNoOverlay: bool,
        isOpen: bool,
        label: string,
        noBorder: bool,
        onClose: func,
        onToggleOverrideClose: func,
        size: string,
        theme: string
    };
    static defaultProps = {
        canClose: true,
        children: '',
        className: '',
        dropdownRight: false,
        forceOverlay: true,
        forceNoOverlay: false,
        isOpen: false,
        label: '',
        onClose: () => { },
        noBorder: false,
        onToggleOverrideClose: () => { },
        size: 'sm',
        theme: 'default'
    };
    constructor(props) {
        super(props);
        const { isOpen } = this.props;
        this.state = {
            isOpen
        };
        this.dropdownButton = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('mousedown', this.handleMouseDown);
        window.addEventListener('touchstart', this.handleMouseDown);
    }
    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('touchstart', this.handleMouseDown);
    }
    handleClickToggle = yieldCallback(() => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    });
    handleMouseDown = (e) => {
        const { onClose } = this.props;
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.handleCloseDropdown(e, onClose);
        }
    };
    handleCloseDropdown = (e, onClose = () => { }) => {
        const { onToggleOverrideClose, canClose } = this.props;
        if (canClose) {
            onClose();
            this.setState({
                isOpen: false
            });

            return;
        }
        // only block closing once - after the user has seen the error, set canClose to true
        onToggleOverrideClose();
    };
    handleRemoveFilterItem = (e) => {
        const { filterKeys, dispatch } = this.props;
        e.stopPropagation();

        dispatch(DEPRECATED_FilterActions.partialResetFilter({ keys: filterKeys }));
        this.handleCloseDropdown();
    };
    handleKeyDown = (e) => {
        const { shiftKey, which } = e;
        // if user opens the dropdown, then immediately back tabs, the dropdown should close
        if (shiftKey && which === constants.TAB_KEYCODE) {
            this.handleCloseDropdown();
        }
    };
    handleDoneButtonKeyDown = (e) => {
        const { which } = e;
        // collapse dropdown when tabbing out
        if (which === constants.TAB_KEYCODE) {
            this.handleCloseDropdown();
        }
    };
    handleDoneButtonClick = (e) => {
        this.handleCloseDropdown(e);
        this.dropdownButton.current.focus();
    };
    render() {
        const {
            children,
            className,
            dropdownRight,
            filterKeys,
            forceOverlay,
            forceNoOverlay,
            label,
            noBorder,
            showDoneButton,
            size,
            theme
        } = this.props;
        const { isOpen } = this.state;
        const rest = omit(this.props, [
            'canClose',
            'onClose',
            'children',
            'className',
            'dispatch',
            'dropdownRight',
            'filterKeys',
            'forceOverlay',
            'forceNoOverlay',
            'isOpen',
            'label',
            'noBorder',
            'onClick',
            'onToggleOverrideClose',
            'showDoneButton',
            'size',
            'theme',
            'zIndexOnTop'
        ]);

        return (
            <S.ButtonDropdown
                className={className}
                theme={theme}
                {...rest}
            >
                <S.ButtonDropdownClickable
                    size={size}
                    onClick={this.handleClickToggle}
                    aria-expanded={isOpen}
                    onKeyDown={this.handleKeyDown}
                    ref={this.dropdownButton}
                >
                    {label && (
                        <S.Text size={size === 'lg' ? 'md' : 'sm'}>
                            {label}
                        </S.Text>
                    )}
                    <S.ButtonDropdownArrow
                        isOpen={isOpen}
                        src={IconArrowGrey}
                        size={size}
                        alt=""
                    />
                </S.ButtonDropdownClickable>
                {isOpen && (
                    <Modal
                        onHidePopup={this.handleCloseDropdown}
                        modalNavOffset
                        forceOverlay={forceOverlay}
                        forceNoOverlay={forceNoOverlay}
                    >
                        <S.ButtonDropdownDropdownContent noBorder={noBorder} dropdownRight={dropdownRight}>
                            {filterKeys && (
                                <S.ButtonDropdownReset onClick={this.handleRemoveFilterItem}>
                                    Reset
                                </S.ButtonDropdownReset>
                            )}
                            {children}
                            {showDoneButton && (
                                <S.ButtonDropdownDoneBtn>
                                    <S.ButtonDropdownDoneBtnSm
                                        onClick={this.handleDoneButtonClick}
                                        onKeyDown={this.handleDoneButtonKeyDown}
                                    >
                                        Done
                                    </S.ButtonDropdownDoneBtnSm>
                                </S.ButtonDropdownDoneBtn>
                            )}
                        </S.ButtonDropdownDropdownContent>
                    </Modal>
                )}
            </S.ButtonDropdown>
        );
    }
}

export default connect()(ButtonDropdown);
