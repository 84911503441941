// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AdActions from 'app/shared/flux/actions/AdActions';
import {
    FilterActions_handleReduxOnlyFilterChange,
    FilterActions_resetRecentSearchFilter
} from 'app/shared/flux/actions/FilterActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import SearchActions from 'app/shared/flux/actions/SearchActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Components
import AutocompleteV2 from 'app/shared/modules/AutocompleteV2';
import BedBathComboFilter from 'app/shared/modules/MobileSRP/Header/FilterModules/BedBathComboFilter';
import Button from 'app/shared/core/Button';
import ButtonDropdown from 'app/shared/modules/filter/ButtonDropdown';
import PriceSlider from 'app/shared/modules/CollectionResultPage/PriceFilter/PriceSlider';
import Linker from 'app/shared/modules/Linker';

// Misc / Utils
import {
    autocompleteHCX,
    autocompleteHContainerCX,
    autocompleteHDropdownCX,
    autocompleteHFiltersCX,
    autocompleteHItemCX,
    autocompleteHItemsCX,
    autocompleteHRCX,
    autocompleteHRContainerMobileCX,
    autocompleteHRFilterCX,
    autocompleteHRFilterContainerCX,
    autocompleteHRFilterDescriptionCX,
    autocompleteHRInputContainerCX,
    autocompleteHRResetCX,
    autocompleteV2CX,
    getAutocompleteHResetAlertHiddenCX,
    lastFilterUsedSpanCX
} from './styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { filterUtils_hasActiveFilters } from 'app/shared/utils/filterUtils';
import controller from './controller';
import queryUtils from 'app/shared/utils/queryUtils';
import SearchItem from 'app/shared/models/SearchItem';
import searchUtils from 'app/shared/utils/searchUtils';
import LabelMaker from 'app/shared/utils/labelMaker';
import { DEFAULT } from 'app/shared/models/Filter';
import { TrackResetFilter } from 'app/shared/models/Clickstream/FilterClickstreamEvents';

const { array, bool, func, string } = PropTypes;
const MAX_SUGGESTIONS = 6;
const MAX_SUGGESTIONS_FULL = 10;
const ALL_AREAS = [
    'borough',
    'city',
    'neighborhood',
    'primaryschool',
    'middleschool',
    'highschool',
    'mixedschool',
    'elemschdist',
    'secschdist',
    'unifschdist',
    'zip'
];

class AutocompleteSearchInput extends React.Component {
    static propTypes = {
        areaTypes: array,
        defaultItemTypes: array,
        defaultSuggestions: array,
        includeCancel: bool,
        includeClear: bool,
        isHomepage: bool,
        full: bool,
        large: bool,
        onSelect: func,
        triggerLocation: string,
        triggerObject: string
    };
    static defaultProps = {
        areaTypes: ALL_AREAS,
        defaultItemTypes: [],
        defaultSuggestions: [],
        includeCancel: false,
        includeClear: false,
        isHomepage: false,
        full: false,
        large: false, // size of AutocompleteSearchInput (not screenWidth)
        onSelect: () => { }
    };
    constructor(props) {
        super(props);
        const { areaName, defaultItemTypes, defaultSuggestions, dispatch, isHomepage, large, recentSearch } =
            this.props;
        const activeSearchItem = controller.findFirstItemMatch(defaultItemTypes, defaultSuggestions, new SearchItem());
        let hasSetFilters = false;

        if (isHomepage && !isEmpty(recentSearch)) {
            const isRecentSearchDefaultFilter = !filterUtils_hasActiveFilters(recentSearch.filter);
            if (!isRecentSearchDefaultFilter) {
                hasSetFilters = true;
            }

            dispatch(FilterActions_handleReduxOnlyFilterChange(recentSearch.filter));
        }

        this.state = {
            activeSearchItem,
            canClosePriceFilter: true,
            help: {
                title: '',
                description: ''
            },
            placeholder: controller.getPlaceholder({ large, areaName }),
            hasSetFilters,
            showResetFilterAlert: false,
            suggestions: defaultSuggestions || []
        };
        this.cachedItem = {};
        this.priceSlider = React.createRef();
    }
    componentDidMount() {
        const { dispatch, recentSearch } = this.props;
        if (isEmpty(recentSearch)) {
            dispatch(UserSearchActions.getRecentSearches());
        }
        dispatch(UserSearchActions.getSavedSearches());
        this.updateMaxSuggestions();
        window.addEventListener('resize', this.updateMaxSuggestions);
    }
    componentWillReceiveProps(nextProps) {
        const { areaName, large, isLoggedIn } = nextProps;
        if (areaName && areaName !== this.props.areaName) {
            this.setState({
                activeSearchItem: new SearchItem(),
                placeholder: controller.getPlaceholder({ large, areaName })
            });
        }
        if (isLoggedIn !== this.props.isLoggedIn) {
            this.props.dispatch(UserSearchActions.getRecentSearches());
            this.props.dispatch(UserSearchActions.getSavedSearches());
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMaxSuggestions);
    }
    updateMaxSuggestions = () => {
        const { full, isMobile } = this.props;
        this.MAX_RESULTS = controller.getMaxNumSuggestions({ full, isMobile, MAX_SUGGESTIONS, MAX_SUGGESTIONS_FULL });
    };
    getResults = debounce(() => {
        const { props, partial } = this;
        const { dispatch, areaTypes, lat, lon, defaultSuggestions } = props;

        return dispatch(
            SearchActions.getSearchItemFromAutocomplete({
                partial,
                limit: this.MAX_RESULTS,
                areaTypes,
                lat,
                lon
            })
        ).then((suggestions) => {
            // must be this.partial
            if (this.partial === '') {
                /**
                 * fixes bug showing previous suggestions when the input is empty
                 * since SearchActions.getSearchItemFromAutocomplete is async
                 */
                this.setState({
                    suggestions: defaultSuggestions
                });
            } else if (suggestions.length) {
                this.setState({
                    help: {
                        title: '',
                        description: ''
                    },
                    suggestions
                });
            } else {
                this.setState({
                    help: {
                        title: 'No matches for your search term.',
                        description: 'Update your search and try again?'
                    },
                    suggestions
                });
            }
        });
    }, 60);
    handleFocus = yieldCallback(() => {
        const { defaultSuggestions, areaName } = this.props;
        const { activeSearchItem } = this.state;

        if (
            isEmpty(activeSearchItem.name) ||
            activeSearchItem.type === 'recentSearch' ||
            activeSearchItem.type === 'geoip' ||
            activeSearchItem.name === areaName
        ) {
            this.cachedItem = activeSearchItem;
            this.setState({
                activeSearchItem: new SearchItem(),
                suggestions: defaultSuggestions
            });
        }
    });
    handleBlur = yieldCallback(() => {
        const { isHomepage } = this.props;
        if (isHomepage) {
            // on the homepage, we want to set activeSearchItem on selection
            return;
        }

        this.handleResetActiveSearchItem();
    });
    handleResetActiveSearchItem = () => {
        const { activeSearchItem } = this.state;
        if (isEmpty(activeSearchItem.name) && !isEmpty(this.cachedItem.name)) {
            this.setState({
                activeSearchItem: this.cachedItem
            });
        }
    };
    yieldGetResults = yieldCallback(() => {
        this.getResults();
    });
    handleChange = (e) => {
        const { defaultSuggestions } = this.props;
        this.partial = e.target.value;
        const activeSearchItem = new SearchItem({
            gaEvent: gaEvents.SEARCH_FREEFORM,
            name: this.partial,
            type: 'freeform'
        });
        this.setState({
            help: {
                title: '',
                description: '',
                term: ''
            },
            activeSearchItem
        });
        if (this.partial === '') {
            this.setState({
                suggestions: defaultSuggestions
            });
        } else if (trim(this.partial).length >= 3) {
            this.yieldGetResults();
        } else {
            this.setState({
                help: {
                    title: 'A minimum of 3 letters is required. Keep typing...',
                    description: ''
                }
            });
        }
    };
    handleClear = () => {
        const { defaultSuggestions } = this.props;
        const activeSearchItem = new SearchItem({
            gaEvent: gaEvents.SEARCH_FREEFORM,
            name: '',
            type: 'freeform'
        });
        this.partial = '';

        this.setState({
            help: {
                title: '',
                description: '',
                term: ''
            },
            activeSearchItem,
            suggestions: defaultSuggestions
        });
    };
    yieldGoToSearchItem = yieldCallback((shouldGo, searchItem) => {
        const { dispatch, onSelect, large, isHomepage, recentSearch } = this.props;
        onSelect();
        dispatch(AdActions.refreshAd(true));
        dispatch(ListingEngineActions.clearPreviewAndCurrent());
        if (shouldGo) {
            dispatch(SearchActions.goToSearchItem(searchItem))
                .then((data) => {
                    if (data.success) {
                        if (searchItem.type === 'area-selection' || isHomepage) {
                            dispatch(
                                UserSearchActions.setCurrentSearch({ waiting: true, resourceId: searchItem.resourceId })
                            );
                        }
                    } else {
                        this.setState({
                            help: {
                                title: "We couldn't find results for ",
                                description: 'Update your search and try again?',
                                term: searchItem.name
                            }
                        });
                    }
                })
                .catch((err) => {
                    if (err.message === "Sorry, we couldn't find that location.") {
                        this.setState({
                            help: {
                                title: "We couldn't find results for ",
                                description: 'Update your search and try again?',
                                term: searchItem.name
                            }
                        });
                    } else {
                        console.error(err);
                        this.setState({
                            help: {
                                title: 'Sorry! Something is not working.'
                            }
                        });
                    }
                });
        }
    });
    handleSelect = (selection = {}, shouldGo = true) => {
        const { canClosePriceFilter, suggestions } = this.state;
        const { dispatch, onSelect, large, isHomepage, recentSearch } = this.props;
        let searchItem;

        if (!canClosePriceFilter) {
            // user entered invalid min or max price
            return;
        }
        if (isObject(selection) && isEmpty(selection.name)) {
            // user did not enter anything - do not perform search
            if (large) {
                this.setState({
                    help: {
                        title: 'Please enter something.',
                        description: ''
                    }
                });
            }
            return;
        }

        if (suggestions.length > 0 && selection.type === 'freeform') {
            searchItem = controller.validateSelection(suggestions[0]);
        } else {
            searchItem = controller.validateSelection(selection);
        }

        this.setState({
            activeSearchItem: searchItem
        });

        if (isHomepage) {
            if (searchItem.type === 'savedSearch') {
                // force a search if a savedSearch was selected
                shouldGo = true;
            } else if (searchItem.type === 'recentSearch' && !recentSearch.filterDescriptionV2) {
                // do not use RecentSearch.url on the homepage in case user sets filters (HPWEB-5509)
                searchItem.shouldBuildUrl = true;
            }
        }
        this.yieldGoToSearchItem(shouldGo, searchItem);
    };
    handleResetFilters = () => {
        const { dispatch } = this.props;
        const { activeSearchItem } = this.state;
        const newActiveSearchItem = controller.getSearchItemSansFilterKeepMap(activeSearchItem);
        this.setState({
            activeSearchItem: newActiveSearchItem,
            hasSetFilters: false,
            showResetFilterAlert: true
        });
        dispatch(FilterActions_handleReduxOnlyFilterChange());
        dispatch(FilterActions_resetRecentSearchFilter());
        setTimeout(() => {
            this.setState({ showResetFilterAlert: false });
        }, 4000);
        dispatch(analyticsEvent(gaEvents.RESET_FILTER, { newLaneEvent: TrackResetFilter() }));
    };
    handleToggleOverrideClose = (canClose = true) => {
        // blocks searching and the closing of priceFilter if the values are invalid
        const { canClosePriceFilter } = this.state;
        if (canClosePriceFilter) {
            clearTimeout(this.errorTimeout);
            this.setState({ canClosePriceFilter: canClose });
        } else {
            // setTimeout allows time for users to read error message, and blocks this.handleSelect
            this.errorTimeout = setTimeout(() => this.setState({ canClosePriceFilter: canClose }), 250);
        }
    };
    renderAutocomplete = () => {
        const { includeCancel, includeClear, full } = this.props;
        const { activeSearchItem, suggestions, help, placeholder } = this.state;

        return (
            <AutocompleteV2
                help={help}
                includeCancel={includeCancel}
                includeClear={includeClear}
                items={suggestions}
                full={full}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onClear={this.handleClear}
                onFocus={this.handleFocus}
                onSelect={this.handleSelect}
                placeholder={placeholder}
                value={activeSearchItem.name}
            />
        );
    };
    renderAutocompleteHomepage = () => {
        const { filter, isMobile, triggerLocation, triggerObject } = this.props;
        const { activeSearchItem, suggestions, help, placeholder, canClosePriceFilter } = this.state;
        const { price, bedrooms } = filter;
        const { min, max } = price;
        const priceLabel = LabelMaker.price({ min, max }) ?? 'Any price';
        const bedroomLabel = LabelMaker.bedrooms(bedrooms) ?? 'All beds';
        const isDefaultFilterValue = (category) => {
            return isEqual(filter[category], DEFAULT[category]);
        };

        return (
            <div className={autocompleteHContainerCX} data-name="AutocompleteHContainer">
                <AutocompleteV2
                    placeholder={placeholder}
                    value={activeSearchItem.name}
                    items={suggestions}
                    help={help}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onClose={this.handleResetActiveSearchItem}
                    onSelect={(item) => this.handleSelect(item, false)}
                    isHomepage
                />
                <div className={autocompleteHFiltersCX} data-name="AutocompleteHFilters">
                    <div className={autocompleteHItemsCX} data-name="AutocompleteHItems">
                        <div className={autocompleteHItemCX} data-name="AutocompleteHItem">
                            <ButtonDropdown
                                label={priceLabel}
                                noBorder
                                showDoneButton
                                size={isMobile ? 'md' : 'lg'}
                                theme={isDefaultFilterValue('price') ? 'default' : 'teal'}

                                forceNoOverlay
                                onClose={() => {
                                    // call PriceSlider's handleInputBlur manually because
                                    // it is unmounted before the input.onBlur can be called
                                    if (this.priceSlider.current) {
                                        this.priceSlider.current.handleInputBlur();
                                    }
                                }}
                                canClose={canClosePriceFilter}
                                onToggleOverrideClose={this.handleToggleOverrideClose}
                            >
                                <div className={autocompleteHDropdownCX} data-name="AutocompleteHDropdown">
                                    <PriceSlider
                                        preventDefault
                                        triggerLocation={triggerLocation}
                                        triggerObject={triggerObject}
                                    />
                                </div>
                            </ButtonDropdown>
                        </div>
                        <div className={autocompleteHItemCX} data-name="AutocompleteHItem">
                            <ButtonDropdown
                                label={bedroomLabel}
                                noBorder
                                dropdownRight={isMobile}
                                showDoneButton
                                size={isMobile ? 'md' : 'lg'}
                                theme={isDefaultFilterValue('bedrooms') === false ? 'teal' : 'default'}
                                forceNoOverlay
                            >
                                <div className={autocompleteHDropdownCX} data-name="AutocompleteHDropdown">
                                    <BedBathComboFilter
                                        hideBath preventDefault
                                        triggerLocation={triggerLocation}
                                        triggerObject={triggerObject}
                                    />
                                </div>
                            </ButtonDropdown>
                        </div>
                    </div>
                    <Button
                        size={isMobile ? 'md' : 'lg'}
                        onClick={() => this.handleSelect(activeSearchItem)}
                    >
                        Search
                    </Button>
                </div>
            </div>
        );
    };
    renderAutocompleteHomepageWithFilterDescription = () => {
        const { recentSearch, isMobile } = this.props;
        const { activeSearchItem, suggestions, help, placeholder } = this.state;

        return isMobile ? (
            <div className={autocompleteHRCX} data-name="AutocompleteHR">
                <div className={autocompleteHRContainerMobileCX} data-name="AutocompleteHRContainerMobileCX">
                    <AutocompleteV2
                        placeholder={placeholder}
                        value={activeSearchItem.name}
                        items={suggestions}
                        help={help}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onSelect={this.handleSelect}
                        isHomepage
                        hideRecentDesc
                    />
                    <div className={autocompleteHRFilterContainerCX} data-name="AutocompleteHRFilterContainer">
                        <span className={autocompleteHRFilterCX} data-name="AutocompleteHRFilter">Last filters used:</span>
                        <span className={lastFilterUsedSpanCX} data-name="LastFilterUsedSpan">{recentSearch.filterDescriptionV2}</span>
                    </div>
                    <Linker className={autocompleteHRResetCX} onClick={this.handleResetFilters} linkType="secondary">
                        Reset filters
                    </Linker>
                    <Button
                        size="md"
                        full={isMobile}
                        onClick={() => this.handleSelect(activeSearchItem)}
                    >
                        Search
                    </Button>
                </div>
            </div>
        ) : (
            <div className={autocompleteHRCX} data-name="AutocompleteHR">
                <div className={autocompleteHRInputContainerCX} data-name="AutocompleteHRInputContainer">
                    <AutocompleteV2
                        className={autocompleteV2CX}
                        placeholder={placeholder}
                        value={activeSearchItem.name}
                        items={suggestions}
                        help={help}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onSelect={this.handleSelect}
                        isHomepage
                        hideRecentDesc
                    />
                    <Button
                        size="lg"
                        onClick={() => this.handleSelect(activeSearchItem)}
                    >
                        Search
                    </Button>
                </div>
                <div className={autocompleteHRFilterContainerCX}>
                    <div className={autocompleteHRFilterDescriptionCX} data-name="AutocompleteHRFilterDescription">
                        <span className={autocompleteHRFilterCX} data-name="AutocompleteHRFilter">Last filters used:</span>
                        <span className={lastFilterUsedSpanCX} data-name="LastFilterUsedSpan">{recentSearch.filterDescriptionV2}</span>
                    </div>
                    <Linker className={autocompleteHRResetCX} onClick={this.handleResetFilters} linkType="secondary">
                        Reset filters
                    </Linker>
                </div>
            </div>
        );
    };
    render() {
        const { recentSearch, isHomepage } = this.props;
        const { hasSetFilters, showResetFilterAlert } = this.state;
        const showHomepageFilters = !(
            hasSetFilters &&
            recentSearch.filterDescriptionV2 &&
            recentSearch.filterDescriptionV2.length > 0
        );
        return isHomepage ? (
            <div className={autocompleteHCX} data-name="AutocompleteH">
                {showHomepageFilters
                    ? this.renderAutocompleteHomepage()
                    : this.renderAutocompleteHomepageWithFilterDescription()}
                <div className={getAutocompleteHResetAlertHiddenCX(showResetFilterAlert)} data-name="AutocompleteHResetAlertHidden">
                    Your filters have been reset.
                </div>
            </div>
        ) : (
            this.renderAutocomplete()
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { location, full, useSmallScreenWidthDropdown } = ownProps;
    const { recent, saved } = state.user.search;
    const { area } = state.geolocation;
    const { screenWidth } = state.app.device;
    const isMobile = screenWidth === 'xs' || screenWidth === 'sm';

    return {
        areaName: state.area.area.fullName,
        defaultSuggestions: searchUtils.getSuggestions({
            sm: screenWidth === 'sm' || useSmallScreenWidthDropdown,
            recent,
            saved,
            geolocationArea: area,
            limit: full && !isMobile ? MAX_SUGGESTIONS_FULL : MAX_SUGGESTIONS
        }),
        filter: state.filter,
        isMobile,
        isLoggedIn: state.user.loggedIn,
        isInitialSsrPage: state.app.isInitialSsrPage,
        lat: queryUtils.parse(location.search).lat || state.geolocation.area.coordinates.lat,
        lon: queryUtils.parse(location.search).lon || state.geolocation.area.coordinates.lon,
        recentSearch: recent,
        savedSearches: saved
    };
};

export default withRouter(connect(mapStateToProps)(AutocompleteSearchInput));
